{
  "appName": "العلامة البيضاء",
  "EGP": "ج.م",
  "addToCart": "أضف إلى السلة",
  "validation.required": "هذا الحقل مطلوب",
  "validation": {
    "required": "هذا الحقل مطلوب",
    "email": "يرجى إدخال عنوان بريد إلكتروني صالح",
    "phone": "يرجى إدخال رقم هاتف صالح",
    "number": "يرجى إدخال رقم صالح",
    "invalidEmailOrPhone": "يرجى إدخال عنوان بريد إلكتروني أو رقم هاتف صالح",
    "password": "الحد الأدنى لكلمة المرور هو 8 أحرف",
    "invalidFullName": "يرجى إدخال اسم كامل صالح",
    "invalidPhone": "يرجى إدخال رقم هاتف صالح",
    "fullName": "يرجى إدخال اسم كامل صالح (الاسم الأول والاسم الأخير)",
    "walletNumberError": "يرجى إدخال رقم محفظة صالح",
    "invalidEmail": "يرجى إدخال عنوان بريد إلكتروني صالح",
    "region": "يرجى اختيار المنطقة",
    "city": "يرجى اختيار المدينة",
    "title": "يرجى إدخال عنوان صالح",
    "street": "يرجى إدخال اسم الشارع"
  },
  "shippingMethods": {
    "deliverWithin": "التسليم خلال {minDays} - {maxDays} يومًا",
    "forOrders": "للطلبات قبل {cutoffTime}",
    "freeOver": "مجاني عند شراء بقيمة أكثر من "
  },
  "default": "افتراضي",
  "Free": "مجاني",
  "phone": "هاتف",
  "seo": {
    "description": "العلامة البيضاء هي متجر إلكتروني يقدم منتجات متنوعة من مختلف الفئات بأسعار منافسة وجودة عالية. تسوق الآن واحصل على أفضل العروض والخصومات",
    "title": "العلامة البيضاء | متجر إلكتروني لبيع المنتجات بأسعار منافسة وجودة عالية"
  },
  "all": "جميع ",
  "categories": "الفئات",
  "home": "الرئيسية",
  "toBeCalculated": "ليتم احتسابها"
}
